<template>
  <div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item
          label="分类"
          prop="cateId"
          v-bind="formItemLayout"
      >
        <a-select :options="cateList" v-model="form.cateId" v-decorator="['cateId']"
                  @change="cateChanged"></a-select>
      </a-form-model-item>
      <a-form-model-item
          ref="title"
          label="评测名称"
          prop="evaName"
          v-bind="formItemLayout">
        <a-input
            v-model="form.evaName"
            allow-clear
            placeholder="输入评测名称"
            v-decorator.trim="['evaName']"
        />
      </a-form-model-item>
      <a-form-model-item label="结果类型" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.resultType" v-decorator="['resultType',
                   {initialValue: 1}]">
          <a-radio :value="1">单一结果</a-radio>
          <a-radio :value="2">关联结果</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否有效" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.status" v-decorator="['status',
                   {initialValue: 1}]">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="测评描述" v-bind="formItemLayout" prop="evaDesc">
        <tiny-mce-editor ref="editor" v-model="form.evaDesc" style="width: 100%"></tiny-mce-editor>
      </a-form-model-item>
      <a-form-model-item label="测试说明" v-bind="formItemLayout" prop="resultPrefix">
        <tiny-mce-editor ref="editor2" v-model="form.resultPrefix" style="width: 100%"></tiny-mce-editor>
      </a-form-model-item>
      <a-form-model-item label="结果说明" v-bind="formItemLayout" prop="resultDesc">
        <tiny-mce-editor ref="editor3" v-model="form.resultDesc" style="width: 100%"></tiny-mce-editor>
      </a-form-model-item>
    </a-form-model>
    <div style="margin:20px 150px;">
      <a-button type="primary" size="large" :loading="confirmLoading" @click="handleOk" style="width:100px;">保存
      </a-button>
    </div>
  </div>
</template>

<script>
import TinyMceEditor from "@/components/Editor/TinyMceEditor";


export default {
  name: "ModifyEvaluationBase",
  components: {TinyMceEditor},
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 17},
      },
      mode: 'new',
      confirmLoading: false,
      form: {},
      cateList: [],
      rules: {
        cateId: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur",
          },
        ],
        evaName: [
          {
            required: true,
            message: "请输入评测名称",
            trigger: "blur",
          },
        ],
        evaDesc: [
          {
            required: true,
            message: "请输入评测描述说明",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.$postJson('/category/cateList', {pageSize: 100, type: 3}).then(res => {
      this.cateList = ((res.data && res.data.records) || [])
          .map(v => {
            return {
              value: v.cateId,
              label: v.cateName
            }
          })
    })
  },
  methods: {
    init(record, mode) {
      this.mode = mode || 'new'
      if (this.mode == 'new') {
        this.form = {
          resultType: 1,
          status: 1
        };
      } else {
        this.form = {
          ...record,
        };
      }
    },
    cateChanged(cateId) {
      let findOne = this.cateList.find(v => v.value == cateId);
      if (findOne) {
        this.form.category = findOne.label
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.evaDesc.indexOf("data:image/") >= 0) {
            this.$message.warn("图片请选择上传方式，不要直接剪切后粘贴！");
            return
          }
          this.confirmLoading = true;
          this.$postJson("evaluation/saveConfig", this.form).then((res) => {
            this.confirmLoading = false;
            this.form.id = res.data;
            this.mode = 'edit'
            this.$message.success("保存成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
